import React from "react"
import styles from './home.module.scss'


export default () => 
    <div className={styles.welcome}>
        <p> Welkom! <br />
         Ik ben er helaas nog steeds niet aan toegekomen om deze site te maken (kinderen van de schoenmaker...)<br />
        Probeer het later nog eens een keer.</p>
    </div>
